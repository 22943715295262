import "./form.scss";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const Form = () => {
  const [sendMes, setSendMes] = useState("");
  const [sizeError, setSizeError] = useState([]);
  const [fileInput, setFileInput] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [inqCountry, setInqCountry] = useState("");
  const [department, setDepartment] = useState("");
  const [message, setMessage] = useState("");
  const [targetCountry, setTargetCountry] = useState("");
  const [targetBrand, setTargetBrand] = useState("");
  const [targetProduct, setTargetProduct] = useState("");
  const [bnf, setBNF] = useState("");
  const [expiry, setExpiry] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [purchaseFrom, setPurchaseFrom] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [productIdea, setProductIdea] = useState("");
  const [brandCategory, setBrandCategory] = useState("");
  useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionSales, setSelectedOptionSales] = useState("");
  const { loading, error, dispatch } = useContext(AuthContext);
  const [brandName, setBrandName] = useState("");
  const [targetFunction, setTargetFunction] = useState("");
  const [mailList, setMailList] = useState([]);

  const [show, setShow] = useState(false);

  //  console.log(selectedOptionSales);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.id);
    // setDepartment("");
  };

  const handleDepartmentChange = (e) => {
    setDepartment(e.target.value);
    setSelectedOption("");
    setSelectedOptionSales("");
  };

  const handleChangeSales = (e) => {
    setSelectedOptionSales(e.target.id);
  };

  const navigate = useNavigate();

  async function uploadPhoto(e) {
    const files = e.target.files;
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    const validFiles = [];
    const invalidFiles = [];

    for (let i = 0; i < files.length; i++) {
      if (files[i].size <= maxFileSize) {
        validFiles.push(files[i]);
      } else {
        invalidFiles.push(files[i]);
      }
    }

    // Alert for any files that were too large
    if (invalidFiles.length > 0) {
      setSizeError(
        `${invalidFiles
          .map((file) => file.name)
          .join(", ")} exceed the 5MB file size limit.`
      );
      fileInput.value = null;
    }

    if (validFiles.length > 0) {
      const data = new FormData();
      validFiles.forEach((file) => data.append("photos", file));

      try {
        const response = await axios.post(
          "https://mail-api.notionsgroup.com/api/upload",
          // "http://localhost:4348/api/upload",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const { data: filenames } = response;
        setPhotos((prev) => [...prev, ...filenames]); // Update state with new file names
        setSizeError("");
      } catch (error) {
        console.error("Error uploading files:", error);
        setSizeError("File upload failed.");
      }
    }
  }

  const senMail = async () => {
    let dataSave = {
      name,
      email,
      phone,
      inqCountry,
      department,
      message,
      targetCountry,
      targetBrand,
      targetProduct,
      bnf,
      targetFunction,
      brandName,
      expiry,
      brandCategory,
      purchaseDate,
      purchaseFrom,
      orderNumber,
      productIdea,
      photos,
      selectedOption,
      selectedOptionSales,
    };

    try {
      await axios.post(
        "https://mail-api.notionsgroup.com/api/auth/sendmail",
        // "http://localhost:3003/api/auth/sendmail",
        dataSave
      );
      // console.log("send mail", mailsend);

      setSendMes("Thanks for contacting will get back soon !");
    } catch (error) {
      console.log("somting want wrong", error);
    }
  };

  const selectMailList = () => {
    if (
      department === "Sales & Distribution Inquiries" &&
      [
        "Georgia",
        "Azerbaijan",
        "Mongolia",
        "Uzbekistan",
        "Kyrgyzstan",
        "Tajikistan",
        "Norway",
        "Finland",
        "Andorra",
        "Estonia",
        "Cyprus",
        "Slovenia",
        "Czech Republic",
        "Lithuania",
        "Slovakia",
        "Latvia",
        "Hungary",
        "Poland",
        "Croatia",
        "Romania",
        "Russian Federation",
        "Montenegro",
        "Serbia",
        "North Macedonia",
        "Belarus",
        "Moldova",
        "Ukraine",
      ].includes(targetCountry) &&
      selectedOptionSales === "export"
    ) {
      return [
        "alexey@notionsgroup.com",
        "allen@notionsgroup.com",
        "apalekar@notionsgroup.com",
      ];
    }
    if (
      department === "Sales & Distribution Inquiries" &&
      [
        "Equatorial Guinea",
        "Gabon",
        "Namibia",
        "Cabo Verde",
        "Sao Tome & Principe",
        "Comoros",
        "Benin",
        "Guinea",
        "Mali",
        "Lesotho",
        "Uganda",
        "Zimbabwe",
        "Gambia",
        "Togo",
        "Guinea-Bissau",
        "Niger",
        "Malawi",
        "Sierra Leone",
        "Central African Republic",
        "Liberia",
        "Eritrea",
        "Somalia",
        "Saint Helena",
        "Western Sahara",
        "Israel",
        "Bhutan",
        "Timor-Leste",
        "Iran",
        "North Korea",
        "Liechtenstein",
        "Luxembourg",
        "Iceland",
        "Austria",
        "San Marino",
        "Portugal",
        "Holy See",
        "Monaco",
        "Faeroe Islands",
        "Isle of Man",
        "Channel Islands",
        "Gibraltar",
        "United States",
        "The Bahamas",
        "Saint Kitts and Nevis",
        "Trinidad and Tobago",
        "Panama",
        "Grenada",
        "Dominican Republic",
        "Saint Lucia",
        "Dominica",
        "Saint Vincent and the Grenadines",
        "Guatemala",
        "Belize",
        "El Salvador",
        "Honduras",
        "Nicaragua",
        "Haiti",
        "Cuba",
        "New Zealand",
        "Others",
        "Argentina",
        "Guyana",
        "Ecuador",
        "Bolivia",
        "French Guiana",
      ].includes(targetCountry) &&
      selectedOptionSales === "export"
    ) {
      return ["allen@notionsgroup.com", "apalekar@notionsgroup.com"];
    }
    if (
      department === "Sales & Distribution Inquiries" &&
      ["Bulgaria", "Bosnia and Herzegovina", "Albania"].includes(
        targetCountry
      ) &&
      selectedOptionSales === "export"
    ) {
      return [
        "hafi@notionsgroup.com",
        "alexey@notionsgroup.com",
        "allen@notionsgroup.com",
        "apalekar@notionsgroup.com",
      ];
    }
    if (
      department === "Sales & Distribution Inquiries" &&
      ["india"].includes(targetCountry) &&
      selectedOptionSales === "export"
    ) {
      return [
        "hafi@notionsgroup.com",
        "mazen@notionsgroup.com",
        "allen@notionsgroup.com",
        "apalekar@notionsgroup.com",
      ];
    }
    if (
      department === "Sales & Distribution Inquiries" &&
      [
        "Botswana",
        "South Africa",
        "Djibouti",
        "Eswatini",
        "Ghana",
        "Côte d'Ivoire",
        "Nigeria",
        "Kenya",
        "Mauritania",
        "Angola",
        "Senegal",
        "Cameroon",
        "Tanzania",
        "Ethiopia",
        "Zambia",
        "South Sudan",
        "Rwanda",
        "Burkina Faso",
        "Congo",
        "Sudan",
        "Chad",
        "DR Congo",
        "Mozambique",
        "Burundi",
        "Singapore",
        "Japan",
        "South Korea",
        "Taiwan",
        "Brunei",
        "Maldives",
        "Malaysia",
        "Indonesia",
        "Sri Lanka",
        "Bangladesh",
        "Pakistan",
        "Nepal",
        "Afghanistan",
        "Lebanon",
        "Ireland",
        "United Kingdom",
        "Canada",
        "Barbados",
        "Antigua and Barbuda",
        "Costa Rica",
        "Mexico",
        "Jamaica",
        "Australia",
        "Guam",
        "Fiji",
        "Uruguay",
        "Chile",
        "Brazil",
        "Peru",
        "Suriname",
        "Colombia",
        "Paraguay",
        "Venezuela",
      ].includes(targetCountry) &&
      selectedOptionSales === "export"
    ) {
      return [
        "hafi@notionsgroup.com",
        "allen@notionsgroup.com",
        "apalekar@notionsgroup.com",
      ];
    }
    if (
      department === "Sales & Distribution Inquiries" &&
      [
        "Seychelles",
        "Mauritius",
        "Comoros Islands",
        "Madagascar",
        "Mayotte",
        "Réunion",
      ].includes(targetCountry) &&
      selectedOptionSales === "export"
    ) {
      return [
        "mazen@notionsgroup.com",
        "allen@notionsgroup.com",
        "apalekar@notionsgroup.com",
      ];
    }
    if (
      department === "Sales & Distribution Inquiries" &&
      [
        "Egypt",
        "Tunisia",
        "Morocco",
        "Algeria",
        "Libya",
        "Qatar",
        "United Arab Emirates",
        "Kuwait",
        "Saudi Arabia",
        "Bahrain",
        "Oman",
        "Turkey",
        "Iraq",
        "Jordan",
        "State of Palestine",
        "Yemen",
        "Syria",
        "Switzerland",
        "Denmark",
        "Netherlands",
        "Sweden",
        "Germany",
        "Belgium",
        "France",
        "Malta",
        "Italy",
        "Spain",
        "Greece",
      ].includes(targetCountry) &&
      selectedOptionSales === "export"
    ) {
      return [
        "sami@notionsgroup.com",
        "allen@notionsgroup.com",
        "apalekar@notionsgroup.com",
      ];
    }
    if (
      department === "Sales & Distribution Inquiries" &&
      [
        "Hong Kong",
        "Macao",
        "China",
        "Thailand",
        "Philippines",
        "Vietnam",
        "Laos",
        "Cambodia",
        "Myanmar",
      ].includes(targetCountry) &&
      selectedOptionSales === "export"
    ) {
      return [
        "yan@notionsgroup.com",
        "allen@notionsgroup.com",
        "apalekar@notionsgroup.com",
      ];
    }
    if (
      department === "Sales & Distribution Inquiries" &&
      selectedOptionSales === "UAE"
    ) {
      return [
        "sami@notionsgroup.com",
        "ahmed@notionsgroup.com",
        "alsondos@notionsgroup.com",
        "apalekar@notionsgroup.com",
        "allen@notionsgroup.com",
      ];
    }
    if (
      department === "Sales & Distribution Inquiries" &&
      selectedOptionSales === "KSA"
    ) {
      return [
        "sami@notionsgroup.com",
        "aouj@notionsgroup.com",
        "ali@notionsgroup.com",
        "apalekar@notionsgroup.com",
        "allen@notionsgroup.com",
      ];
    }
    if (
      department === "Sales & Distribution Inquiries" &&
      selectedOptionSales === "private label"
    ) {
      return [
        "rasha@notionsgroup.com",
        "sami@notionsgroup.com",
        "apalekar@notionsgroup.com",
        "allen@notionsgroup.com",
      ];
    }
    if (department === "HR & Recruitment") {
      return [
        "simran@notionsgroup.com",
        "syedsultan@notionsgroup.com",
        "apalekar@notionsgroup.com",
        "allen@notionsgroup.com",
      ];
    }
    if (
      department === "Customer Support" &&
      selectedOption === "product feedback" &&
      brandCategory === "CHOCOLATE"
    ) {
      return [
        "irina@notionsgroup.com",
        "apalekar@notionsgroup.com",
        "biswas@notionsgroup.com",
        "anirudh@notionsgroup.com",
        "mohammad@notionsgroup.com",
        "jjohn@notionsgroup.com",
        "allen@notionsgroup.com",
      ];
    }
    if (
      department === "Customer Support" &&
      selectedOption === "product feedback" &&
      brandCategory === "SNACKS"
    ) {
      return [
        "qcstarfood@notionsgroup.com",
        "ali@notionsgroup.com",
        "azharproduction@notionsgroup.com",
        "apalekar@notionsgroup.com",
        "allen@notionsgroup.com",
      ];
    }
    if (
      department === "Customer Support" &&
      selectedOption === "order tracking"
    ) {
      return [
        "apalekar@notionsgroup.com",
        "allen@notionsgroup.com",
        "outlet@notionsgroup.com",
      ];
    }
    if (
      department === "Customer Support" &&
      selectedOption === "innovation center"
    ) {
      return [
        "anirudh@notionsgroup.com",
        "jjohn@notionsgroup.com",
        "qcstarfood@notionsgroup.com",
        "ali@notionsgroup.com",
        "pamela@notionsgroup.com",
        "ahmed@notionsgroup.com",
        "apalekar@notionsgroup.com",
        "allen@notionsgroup.com",
      ];
    }
    if (department === "Marketing & Branding") {
      return [
        "apalekar@notionsgroup.com",
        "pamela@notionsgroup.com",
        "ahmed@notionsgroup.com",
        "alsondos@notionsgroup.com",
        "basil@notionsgroup.com",
        "allen@notionsgroup.com",
      ];
    }
    if (department === "General inquiries") {
      return [
        "apalekar@notionsgroup.com",
        "allen@notionsgroup.com",
        "webmaster@notionsgroup.com",
      ];
    } else {
      return [
        "apalekar@notionsgroup.com",
        "allen@notionsgroup.com",
        "webmaster@notionsgroup.com",
      ];
    }
  };

  useEffect(() => {
    const fetchedMailList = selectMailList(); // Assuming selectMailList is a function that returns your list
    setMailList(fetchedMailList);
  }, [
    department,
    selectedOption,
    selectedOptionSales,
    brandCategory,
    targetCountry,
  ]);

  //  console.log(mailList);

  const hadlleSubmit = async (e) => {
    e.preventDefault();
    let dataSave = {
      name,
      email,
      mailList,
      phone,
      inqCountry,
      department,
      message,
      targetCountry,
      targetBrand,
      targetProduct,
      bnf,
      targetFunction,
      brandName,
      expiry,
      brandCategory,
      purchaseDate,
      purchaseFrom,
      orderNumber,
      productIdea,
      photos,
      selectedOption,
      selectedOptionSales,
    };
    try {
      const res = await axios.post(
        "https://mail-api.notionsgroup.com/api/auth/register",
        // "http://localhost:4348/api/auth/register",
        dataSave
      );
      // console.log("Thanks for review");
      senMail();
      // navigate("/");
      setShow(true);
       resetFormFields();
      
    } catch (err) {
      console.log("somting want wrong");
    }
   
    
  };

  const resetFormFields = () => {
    setSendMes("");
    setSizeError("");
    setFileInput("");
    setPhotos("");
    setName("");
    setEmail("");
    setPhone("");
    setInqCountry("");
    setDepartment("");
    setMessage("");
    setTargetCountry("");
    setTargetBrand("");
    setTargetProduct("");
    setBNF("");
    setExpiry("");
    setPurchaseDate("");
    setPurchaseFrom("");
    setOrderNumber("");
    setProductIdea("");
    setBrandCategory("");
    setSelectedOption("");
    setSelectedOptionSales("");
    setBrandName("");
    setTargetFunction("");
    setMailList("");
  };

  const popUpClose = () => {
    setShow(false);
    navigate("/");
    scrollTop()
  };

  return (
    <>
      {show && (
        <div className="__popUp">
          <div className="popEdit__">
            <div className="__popuInfo">
              <div className="__oky">
                <img src={require("../../img/okay.gif")} alt="" />
              </div>
              <div className="popup_tx">
                <h2>Your form has been successfully submitted.</h2>
                <br />
                <p>
                  Thank you for reaching out! Our team will review your
                  information, and one of our representatives will get back to
                  you shortly.
                </p>
              </div>
            </div>
            <div className="close__" onClick={popUpClose}>
              <span>Home</span>
            </div>
          </div>
        </div>
      )}
      <div className="__contact_form">
        <form onSubmit={hadlleSubmit}>
          <div className="__form_in">
            <input
              name="name"
              id="name"
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Name"
            />
            <input
              name="email"
              type="email"
              placeholder="E mail"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <input
              type="tel"
              placeholder="Phone Number"
              name="phone"
              id="phone"
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>

          <div className="_country">
            <div className="con_input">
              <select
                required
                class="con_sel"
                id="country"
                name="country"
                onChange={(e) => setInqCountry(e.target.value)}
              >
                color: rgb(180, 180, 180);
                <option style={{ fontSize: "16px", color: "gray" }}>
                  Country{" "}
                </option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Aland Islands">Aland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire, Sint Eustatius and Saba">
                  Bonaire, Sint Eustatius and Saba
                </option>
                <option value="Bosnia and Herzegovina">
                  Bosnia and Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">
                  British Indian Ocean Territory
                </option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">
                  Cocos (Keeling) Islands
                </option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, Democratic Republic of the Congo">
                  Congo, Democratic Republic of the Congo
                </option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curacao">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="DJibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">
                  Falkland Islands (Malvinas)
                </option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">
                  French Southern Territories
                </option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-Bissau">Guinea-Bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">
                  Heard Island and Mcdonald Islands
                </option>
                <option value="Holy See (Vatican City State)">
                  Holy See (Vatican City State)
                </option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">
                  Iran, Islamic Republic of
                </option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">
                  Korea, Democratic People's Republic of
                </option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kosovo">Kosovo</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">
                  Lao People's Democratic Republic
                </option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">
                  Libyan Arab Jamahiriya
                </option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, the Former Yugoslav Republic of">
                  Macedonia, the Former Yugoslav Republic of
                </option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">
                  Micronesia, Federated States of
                </option>
                <option value="Moldova, Republic of">
                  Moldova, Republic of
                </option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepa">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">
                  Netherlands Antilles
                </option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">
                  Northern Mariana Islands
                </option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">
                  Palestinian Territory, Occupied
                </option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Barthelemy">Saint Barthelemy</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">
                  Saint Kitts and Nevis
                </option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Martin">Saint Martin</option>
                <option value="Saint Pierre and Miquelon">
                  Saint Pierre and Miquelon
                </option>
                <option value="Saint Vincent and the Grenadines">
                  Saint Vincent and the Grenadines
                </option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">
                  Sao Tome and Principe
                </option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Serbia and Montenegro">
                  Serbia and Montenegro
                </option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Sint Maarten">Sint Maarten</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and the South Sandwich Islands">
                  South Georgia and the South Sandwich Islands
                </option>
                <option value="South Sudan">South Sudan</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">
                  Svalbard and Jan Mayen
                </option>
                <option value="Swaziland">Swaziland</option>
                <option value="Swede">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">
                  Syrian Arab Republic
                </option>
                <option value="Taiwan, Province of China">
                  Taiwan, Province of China
                </option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">
                  Tanzania, United Republic of
                </option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-Leste">Timor-Leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">
                  Turks and Caicos Islands
                </option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">
                  United States Minor Outlying Islands
                </option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">
                  Virgin Islands, British
                </option>
                <option value="Virgin Islands, U.s.">
                  Virgin Islands, U.s.
                </option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
            </div>
          </div>

          <div className="con_input">
            {/* <h3 style={{margin:"0", padding:"0"}} >Select your inquiry</h3> */}
            <select
              required
              className="con_sel"
              id="department"
              name="department"
              onChange={handleDepartmentChange}
            >
              <option
                style={{ fontSize: "16px", color: "gray", fontWeight: "400" }}
              >
                Select your inquiry
              </option>
              <hr />

              <option value="Customer Support">Customer Support</option>

              <option value="Sales & Distribution Inquiries">
                Sales & Distribution Inquiries
              </option>

              <option value="HR & Recruitment">
                HR & Recruitment <small>Services</small>{" "}
              </option>

              <option value="Marketing & Branding">
                Marketing & Branding{" "}
              </option>

              <option value="General inquiries">General inquiries</option>
            </select>
          </div>

          {department === "Sales & Distribution Inquiries" && (
            <>
              <div className="radio_sec">
                <label>Export</label>
                <br />
                <input
                  type="radio"
                  placeholder=""
                  name="feedbackOption"
                  id="export"
                  onChange={handleChangeSales}
                  required
                />
              </div>
              <div className="radio_sec">
                <label>Duty Free </label>
                <br />
                <input
                  type="radio"
                  placeholder=""
                  name="feedbackOption"
                  id="dutyFree"
                  onChange={handleChangeSales}
                  required
                />
              </div>
              <div className="radio_sec">
                <label>Private Labels</label>
                <br />
                <input
                  type="radio"
                  placeholder=""
                  name="feedbackOption"
                  id="private label"
                  onChange={handleChangeSales}
                  required
                />
              </div>
              <div className="radio_sec">
                <label>UAE</label>
                <br />
                <input
                  type="radio"
                  placeholder=""
                  name="feedbackOption"
                  id="UAE"
                  onChange={handleChangeSales}
                  required
                />
              </div>
              <div className="radio_sec">
                <label>KSA</label>
                <br />
                <input
                  type="radio"
                  placeholder=""
                  name="feedbackOption"
                  id="KSA"
                  onChange={handleChangeSales}
                  required
                />
              </div>
            </>
          )}

          {selectedOptionSales === "export" && (
            <>
              <div className="_country">
                <div className="con_input">
                  <select
                    required
                    class="con_sel"
                    id="country"
                    name="country"
                    onChange={(e) => setTargetCountry(e.target.value)}
                  >
                    color: rgb(180, 180, 180);
                    <option style={{ fontSize: "16px", color: "gray" }}>
                      Target Market
                    </option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Aland Islands">Aland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire, Sint Eustatius and Saba">
                      Bonaire, Sint Eustatius and Saba
                    </option>
                    <option value="Bosnia and Herzegovina">
                      Bosnia and Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">
                      British Indian Ocean Territory
                    </option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos (Keeling) Islands">
                      Cocos (Keeling) Islands
                    </option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, Democratic Republic of the Congo">
                      Congo, Democratic Republic of the Congo
                    </option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curacao">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="DJibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (Malvinas)">
                      Falkland Islands (Malvinas)
                    </option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">
                      French Southern Territories
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and Mcdonald Islands">
                      Heard Island and Mcdonald Islands
                    </option>
                    <option value="Holy See (Vatican City State)">
                      Holy See (Vatican City State)
                    </option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran, Islamic Republic of">
                      Iran, Islamic Republic of
                    </option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea, Democratic People's Republic of">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="Korea, Republic of">
                      Korea, Republic of
                    </option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao People's Democratic Republic">
                      Lao People's Democratic Republic
                    </option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">
                      Libyan Arab Jamahiriya
                    </option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia, the Former Yugoslav Republic of">
                      Macedonia, the Former Yugoslav Republic of
                    </option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia, Federated States of">
                      Micronesia, Federated States of
                    </option>
                    <option value="Moldova, Republic of">
                      Moldova, Republic of
                    </option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepa">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">
                      Netherlands Antilles
                    </option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">
                      Northern Mariana Islands
                    </option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestinian Territory, Occupied">
                      Palestinian Territory, Occupied
                    </option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">
                      Russian Federation
                    </option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Barthelemy">Saint Barthelemy</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Martin">Saint Martin</option>
                    <option value="Saint Pierre and Miquelon">
                      Saint Pierre and Miquelon
                    </option>
                    <option value="Saint Vincent and the Grenadines">
                      Saint Vincent and the Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">
                      Sao Tome and Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Serbia and Montenegro">
                      Serbia and Montenegro
                    </option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Sint Maarten">Sint Maarten</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and the South Sandwich Islands">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">
                      Svalbard and Jan Mayen
                    </option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Swede">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syrian Arab Republic">
                      Syrian Arab Republic
                    </option>
                    <option value="Taiwan, Province of China">
                      Taiwan, Province of China
                    </option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania, United Republic of">
                      Tanzania, United Republic of
                    </option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-Leste">Timor-Leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">
                      Turks and Caicos Islands
                    </option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">
                      United States Minor Outlying Islands
                    </option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Viet Nam">Viet Nam</option>
                    <option value="Virgin Islands, British">
                      Virgin Islands, British
                    </option>
                    <option value="Virgin Islands, U.s.">
                      Virgin Islands, U.s.
                    </option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
              </div>

              <div className="_country">
                <div className="con_input">
                  <select
                    class="con_sel"
                    id="tgProduct"
                    name="tgProduct"
                    onChange={(e) => setTargetBrand(e.target.value)}
                  >
                    color: rgb(180, 180, 180);
                    <option style={{ fontSize: "16px", color: "gray" }}>
                      Target Brand
                    </option>
                    <option value="APRONS N LOVE">APRONS N LOVE</option>
                    <option value="ARABIAN DELIGHTS">ARABIAN DELIGHTS</option>
                    <option value="CHOCODATE">CHOCODATE</option>
                    <option value="DARA">DARA</option>
                    <option value="MOJO">MOJO</option>
                    <option value="ROUGE">ROUGE</option>
                    <option value="VENICE">VENICE</option>
                    <option value="WISHES">WISHES</option>
                    <option value="ZEIN">ZEIN</option>
                    <option value="XL">XL</option>
                    <option value="RAJA">RAJA</option>
                    <option value="AMIGO">AMIGO</option>
                    <option value="FIESTA">FIESTA</option>
                    <option value="OTHER">OTHER</option>
                  </select>
                </div>
              </div>
            </>
          )}

          {/* {selectedOptionSales === "local" && (
          <>
            <div className="_country">
              <div className="con_input">
                <select
                  class="con_sel"
                  id="tgProduct"
                  name="tgProduct"
                  onChange={(e) => setTargetProduct(e.target.value)}
                >
                  color: rgb(180, 180, 180);
                  <option style={{ fontSize: "16px", color: "gray" }}>
                    Target Product
                  </option>
                  <option value="ALMOND FLOUR">ALMOND FLOUR</option>
                  <option value="BALOURIH">BALOURIH</option>
                  <option value="CHOCOLATE">CHOCOLATE</option>
                  <option value="CHOCOLATE APRICOT">CHOCOLATE APRICOT</option>
                  <option value="CHOCOLATE CHIPS">CHOCOLATE CHIPS</option>
                  <option value="CHOCOLATE DATE">CHOCOLATE DATE</option>
                  <option value="CHOCOLATE FIG">CHOCOLATE FIG</option>
                  <option value="COATED NUTS">COATED NUTS</option>
                  <option value="COCONUT SUGAR">COCONUT SUGAR</option>
                  <option value="CROQANT">CROQANT</option>
                  <option value="ENERGY SNACK">ENERGY SNACK</option>
                  <option value="EXTRUDED SNACKS">EXTRUDED SNACKS</option>
                  <option value="MALBAN">MALBAN</option>
                  <option value="MARZIPAN">MARZIPAN</option>
                  <option value="NOUGAT">NOUGAT</option>
                  <option value="NUT BUTTER">NUT BUTTER</option>
                  <option value="PLAIN DATE">PLAIN DATE</option>
                  <option value="POTATO CHIPS">POTATO CHIPS</option>
                  <option value="PROTEIN BALL">PROTEIN BALL</option>
                  <option value="PUFF SNACKS">PUFF SNACKS</option>{" "}
                  <option value="TORTILLA">TORTILLA</option>
                  <option value="UNCOATED NUTS">UNCOATED NUTS</option>
                </select>
              </div>
            </div>
          </>
        )} */}

          {selectedOptionSales === "private" && (
            <>
              <div className="_country">
                <div className="con_input">
                  <select
                    required
                    class="con_sel"
                    id="country"
                    name="country"
                    onChange={(e) => setTargetCountry(e.target.value)}
                  >
                    color: rgb(180, 180, 180);
                    <option style={{ fontSize: "16px", color: "gray" }}>
                      Target Market{" "}
                    </option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Aland Islands">Aland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire, Sint Eustatius and Saba">
                      Bonaire, Sint Eustatius and Saba
                    </option>
                    <option value="Bosnia and Herzegovina">
                      Bosnia and Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">
                      British Indian Ocean Territory
                    </option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos (Keeling) Islands">
                      Cocos (Keeling) Islands
                    </option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, Democratic Republic of the Congo">
                      Congo, Democratic Republic of the Congo
                    </option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curacao">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="DJibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (Malvinas)">
                      Falkland Islands (Malvinas)
                    </option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">
                      French Southern Territories
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and Mcdonald Islands">
                      Heard Island and Mcdonald Islands
                    </option>
                    <option value="Holy See (Vatican City State)">
                      Holy See (Vatican City State)
                    </option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran, Islamic Republic of">
                      Iran, Islamic Republic of
                    </option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea, Democratic People's Republic of">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="Korea, Republic of">
                      Korea, Republic of
                    </option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao People's Democratic Republic">
                      Lao People's Democratic Republic
                    </option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">
                      Libyan Arab Jamahiriya
                    </option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia, the Former Yugoslav Republic of">
                      Macedonia, the Former Yugoslav Republic of
                    </option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia, Federated States of">
                      Micronesia, Federated States of
                    </option>
                    <option value="Moldova, Republic of">
                      Moldova, Republic of
                    </option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepa">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">
                      Netherlands Antilles
                    </option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">
                      Northern Mariana Islands
                    </option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestinian Territory, Occupied">
                      Palestinian Territory, Occupied
                    </option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">
                      Russian Federation
                    </option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Barthelemy">Saint Barthelemy</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Martin">Saint Martin</option>
                    <option value="Saint Pierre and Miquelon">
                      Saint Pierre and Miquelon
                    </option>
                    <option value="Saint Vincent and the Grenadines">
                      Saint Vincent and the Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">
                      Sao Tome and Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Serbia and Montenegro">
                      Serbia and Montenegro
                    </option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Sint Maarten">Sint Maarten</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and the South Sandwich Islands">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">
                      Svalbard and Jan Mayen
                    </option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Swede">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syrian Arab Republic">
                      Syrian Arab Republic
                    </option>
                    <option value="Taiwan, Province of China">
                      Taiwan, Province of China
                    </option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania, United Republic of">
                      Tanzania, United Republic of
                    </option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-Leste">Timor-Leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">
                      Turks and Caicos Islands
                    </option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">
                      United States Minor Outlying Islands
                    </option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Viet Nam">Viet Nam</option>
                    <option value="Virgin Islands, British">
                      Virgin Islands, British
                    </option>
                    <option value="Virgin Islands, U.s.">
                      Virgin Islands, U.s.
                    </option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
              </div>

              <div className="_country">
                <div className="con_input">
                  <select
                    required
                    class="con_sel"
                    id="tgProduct"
                    name="tgProduct"
                    onChange={(e) => setTargetProduct(e.target.value)}
                  >
                    color: rgb(180, 180, 180);
                    <option style={{ fontSize: "16px", color: "gray" }}>
                      Target Brand
                    </option>
                    <option value="APRONS N LOVE">APRONS N LOVE</option>
                    <option value="ARABIAN DELIGHTS">ARABIAN DELIGHTS</option>
                    <option value="CHOCODATE">CHOCODATE</option>
                    <option value="DARA">DARA</option>
                    <option value="MOJO">MOJO</option>
                    <option value="ROUGE">ROUGE</option>
                    <option value="VENICE">VENICE</option>
                    <option value="WISHES">WISHES</option>
                    <option value="ZEIN">ZEIN</option>
                    <option value="XL">XL</option>
                    <option value="RAJA">RAJA</option>
                    <option value="AMIGO">AMIGO</option>
                    <option value="FIESTA">FIESTA</option>
                    <option value="OTHER">OTHER</option>
                  </select>
                </div>
              </div>
            </>
          )}

          {department === "Marketing & Branding" && (
            <>
              <small
                style={{
                  marginBottom: "10px",
                  backgroundColor: "hsl(205, 100%, 85%)",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  borderRadius: "5px",
                }}
              >
                Services, Sponsorship, Collaboration
              </small>
              <br />
              <span>
                Upload the credential and proposal (Max file size: 5MB)
                {sizeError && (
                  <small style={{ color: "red", fontSize: "12px" }}>
                    {sizeError}
                  </small>
                )}
              </span>
              <input
                ref={(input) => setFileInput(input)}
                onChange={uploadPhoto}
                type="file"
                multiple
              />
            </>
          )}
          {department === "HR & Recruitment" && (
            <>
              <div className="_country">
                <div className="con_input">
                  <select
                    class="con_sel"
                    onChange={(e) => setTargetFunction(e.target.value)}
                  >
                    color: rgb(180, 180, 180);
                    <option style={{ fontSize: "16px", color: "gray" }}>
                      Target Function
                    </option>
                    <option value="Sales">Sales</option>
                    <option value="Business Development">
                      Business Development
                    </option>
                    <option value="Marketing">Marketing</option>
                    <option value="Product Development">
                      Product Development
                    </option>
                    <option value="Supply Chain">Supply Chain</option>
                    <option value="Production">Production</option>
                    <option value="Quality">Quality</option>
                    <option value="Accounting & Finance">
                      Accounting & Finance
                    </option>
                    <option value="Human Resources">Human Resources</option>
                    <option value="IT">IT</option>
                    <option value="Customer Service">Customer Service</option>
                    <option value="Legal & Compliance">
                      Legal & Compliance
                    </option>
                  </select>
                </div>
              </div>

              <label>Upload Resume (Max file size: 5MB) </label>
              {sizeError && (
                <small style={{ color: "red", fontSize: "12px" }}>
                  {sizeError}
                </small>
              )}
              <br />
              <input
                ref={(input) => setFileInput(input)}
                onChange={uploadPhoto}
                type="file"
                multiple
              />
            </>
          )}

          {department === "Customer Support" && (
            <>
              <div className="radio_sec">
                <label>Product Feedback / Complaints</label>
                <br />
                <input
                  type="radio"
                  placeholder=""
                  name="feedbackOption"
                  id="product feedback"
                  onChange={handleRadioChange}
                />
              </div>
              <div className="radio_sec">
                <label>E Commerce Order Tracking</label>
                <br />
                <input
                  type="radio"
                  placeholder=""
                  name="feedbackOption"
                  id="order tracking"
                  onChange={handleRadioChange}
                />
              </div>
              <div className="radio_sec">
                <label>Innovation Center</label>
                <br />
                <input
                  type="radio"
                  placeholder=""
                  name="feedbackOption"
                  id="innovation center"
                  onChange={handleRadioChange}
                />
              </div>
            </>
          )}

          {selectedOption === "product feedback" && (
            <>
              <div className="_country">
                <div className="con_input">
                  <select onChange={(e) => setBrandCategory(e.target.value)}>
                    color: rgb(180, 180, 180);
                    <option style={{ fontSize: "16px", color: "gray" }}>
                      Brand Category
                    </option>
                    <option value="CHOCOLATE">CHOCOLATE</option>
                    <option value="SNACKS">SNACKS</option>
                  </select>
                </div>
              </div>
              <div>
                <input
                  type="tel"
                  placeholder="BNF Number"
                  name="BNFCode"
                  id="BNFCode"
                  onChange={(e) => setBNF(e.target.value)}
                />

                <img
                  style={{ height: "60px" }}
                  src={require("../../img/bnf.jpg")}
                  alt=""
                />
              </div>
              <br />
              <label>Expiry Date</label>
              <br />
              <input
                type="date"
                placeholder=""
                name="expiry "
                id="expiry"
                onChange={(e) => setExpiry(e.target.value)}
              />
              <br />
              <label>Purchase Date</label>
              <br />
              <input
                type="date"
                placeholder="purchase"
                name="purchase"
                id="purchase"
                onChange={(e) => setPurchaseDate(e.target.value)}
              />
              <br />
              <input
                type="text"
                placeholder="Purchase From"
                name="Purchase"
                id="Purchase"
                onChange={(e) => setPurchaseFrom(e.target.value)}
              />
              <br />
              <label>Upload Photos (Max file size: 5MB)</label>
              {sizeError && (
                <small style={{ color: "red", fontSize: "12px" }}>
                  {sizeError}
                </small>
              )}
              <br />
              <input
                ref={(input) => setFileInput(input)}
                onChange={uploadPhoto}
                type="file"
                multiple
              />
              <br />
              <div className="_country">
                <div className="con_input">
                  <select
                    class="con_sel"
                    onChange={(e) => setBrandName(e.target.value)}
                  >
                    color: rgb(180, 180, 180);
                    <option style={{ fontSize: "16px", color: "gray" }}>
                      Brand Name
                    </option>
                    <option value="APRONS N LOVE">APRONS N LOVE</option>
                    <option value="ARABIAN DELIGHTS">ARABIAN DELIGHTS</option>
                    <option value="CHOCODATE">CHOCODATE</option>
                    <option value="DARA">DARA</option>
                    <option value="MOJO">MOJO</option>
                    <option value="ROUGE">ROUGE</option>
                    <option value="VENICE">VENICE</option>
                    <option value="WISHES">WISHES</option>
                    <option value="ZEIN">ZEIN</option>
                    <option value="XL">XL</option>
                    <option value="RAJA">RAJA</option>
                    <option value="AMIGO">AMIGO</option>
                    <option value="FIESTA">FIESTA</option>
                    <option value="OTHER">OTHER</option>
                  </select>
                </div>
              </div>
            </>
          )}
          {selectedOption === "order tracking" && (
            <>
              <input
                type="Text"
                placeholder="Order Number"
                name="orderNum"
                id="orderNum"
                onChange={(e) => setOrderNumber(e.target.value)}
                required
              />
            </>
          )}

          {selectedOption === "innovation center" ? (
            <>
              <textarea
                name="Describe your Product"
                id="message"
                onChange={(e) => setProductIdea(e.target.value)}
                typeof="text"
                placeholder="Describe your product ideas"
                required
              />
            </>
          ) : (
            <>
              <textarea
                name="message"
                id="message"
                onChange={(e) => setMessage(e.target.value)}
                typeof="text"
                placeholder="Message"
                required
              />
            </>
          )}

          {/* <textarea
          name="message"
          id="message"
          onChange={(e) => setMessage(e.target.value)}
          typeof="text"
          placeholder="Message"
        /> */}
          <button typeof="submit">Contact Now</button>
        </form>
        <small>{sendMes}</small>
      </div>
    </>
  );
};

export default Form;
