import React, { useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import "./WorldMap.scss";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { Tooltip } from "react-tooltip";


const WorldMap = () => {
  const [content, setContent] = useState("");
  return (
    <div className="map_container">
      <div className="__world_map">
        <ComposableMap>
          <Geographies geography="/features.json">
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  className="map_color"
                  key={geo.rsmKey}
                  geography={geo}
                />
              ))
            }
          </Geographies>

          <Marker coordinates={[54, 24]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>UAE</span> <p>Chocodate</p></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>
          <Marker coordinates={[52, 25.3]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Bahrain</span> <p>Chocodate</p></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>
          <Marker coordinates={[45, 24]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>KSA</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
                borderRadius: "30px",
              }}
            />
          </Marker>

          <Marker coordinates={[45, 40]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Armenia</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>
          <Marker coordinates={[48, 40.5]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Azerbaijan</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[90, 24]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Bangladesh</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>
          <Marker coordinates={[28, 54]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Belarus</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[130, -20]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Australia</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[25.5, 43]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Bulgaria</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[12, 5]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Cameroon</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[78, 20]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>India</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[-85, 50]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Canada</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[110, 30]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>China</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[16, 45]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Croatia</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[43, 12]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Djibouti</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[30, 30]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Egypt</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[10, 52]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Germany</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[-2, 53]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Great Britain</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[114, 23]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Hong Kong</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[19, 47.5]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Hungary</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[122, -4]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Indonesia</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[42.6, 33]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Iraq</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[138, 36.5]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Japan</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[36, 32]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Jordan</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[68, 48]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Kazakhstan</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[37, 2]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Kenya</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[128, 37]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Korea</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[20, 44]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Kosovo</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[47, 29.2]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Kuwait</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[70, 39]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Kyrgyzstan</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[20, 28]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Libya</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[102, 4]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Malaysia</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[-5, 34]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Morocco</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[7, 9]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Nigeria</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[57, 22]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Oman</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[68, 30]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Pakistan </br> Chocodate  </span>  </div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[68, 30]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Pakistan</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[36, 35]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Palestine</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[25, 46]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Romania</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[80, 60]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Russia</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[103, 2]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Singapore</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[22, -30]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>South Africa</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[30, 14]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Sudan</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[14.5, 60]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Sweden</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[33, -5]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Tanzania</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[102, 16]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Thailand</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[102, 16]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Thailand</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[-62, 11.5]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Trinidad and Tobago</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[9, 34]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Tunisia</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[32, 40]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Turkey</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[30, 50]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Ukraine</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[-100, 40]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>USA</span></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[65, 41]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div><span>Uzbekistan</span> <p>Chocodate</p></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>

          <Marker coordinates={[48, 16.5]}>
            <circle
              id="my-tooltip-1"
              cx={1}
              cy={1}
              r={3}
              data-tooltip-html="<div>  <span>Yemen</span> <p>Chocodate</p></div>"
              style={{
                stroke: "#FF5722",
                strokeWidth: 1.5,
                opacity: 0.9,
                cursor: "pointer",
              }}
            />
          </Marker>
        </ComposableMap>

        {/* country list */}

        <Tooltip
          style={{
            backgroundColor: "#fcb315",
            color: "white",
            fontSize: "10px",
          }}
          anchorSelect="#my-tooltip-1"
          place="top"
          data-html={true}
          multiline={true}
        />
      </div>
      <div className="country_name">
        <div className="con_name">
          <h4>Asia</h4>
          <div className="con_sub">
            <ul>
              <li>Armenia</li>
              <li>Azerbaijan</li>
              <li>Bahrain</li>
              <li>Bangladesh</li>
              <li>Armenia</li>
              <li>China</li>
              <li>Hong Kong</li>
              <li>India</li>
              <li>Indonesia</li>
              <li>Iraq</li>
            </ul>
            <ul>
              <li>Japan</li>
              <li>Jordan</li>
              <li>Kazakhstan</li>
              <li>Korea</li>
              <li>Kuwait</li>
              <li>Kyrgyzstan</li>
              <li>Libya</li>
              <li>Malaysia</li>
              <li>Oman</li>
              <li>Pakistan</li>
            </ul>
            <ul>
              <li>Palestine</li>
              <li>Saudi Arabia</li>
              <li>Singapore</li>
              <li>South Africa</li>
              <li>Taiwan</li>
              <li>Thailand</li>
              <li>Russia</li>
              <li>Turkey</li>
              <li>UAE</li>
              <li>Uzbekistan</li>
              <li>Yemen</li>
            </ul>
            <div className="con_name">
              <h4>Africa</h4>
              <ul>
                <li>Cameroon</li>
                <li>Djibouti</li>
                <li>Egypt</li>
                <li>Kenya</li>
                <li>Morocco</li>
                <li>Nigeria</li>
                <li>Sudan</li>
                <li>Tanzania</li>
                <li>Tunisia</li>
              </ul>
            </div>
            <div>
              <div className="con_name">
                <h4>North America</h4>
                <ul>
                  <li>Canada</li>
                  <li>USA</li>
                </ul>
              </div>

              <div className="con_name">
                <h4>South America</h4>
                <ul>
                  <li>Trinidad and Tobago</li>
                </ul>
              </div>
              <div className="con_sub">
                <div className="con_name">
                  <h4>Australia</h4>
                </div>
              </div>
            </div>
            <div className="con_name">
              <h4>Europe</h4>
              <ul>
                <li>Belarus</li>
                <li>Bulgaria</li>
                <li>Croatia</li>
                <li>Germany</li>
                <li>Great Britain</li>
                <li>Hungary</li>
                <li>Kosovo</li>
                <li>Romania</li>
                <li>Sweden</li>
                <li>Ukraine</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="con_sub"></div>
      </div>
    </div>
  );
};

export default WorldMap;
