import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import NavBar from "./components/navbar/NavBar";
import Footer from "./components/footer/Footer";
import About from "./pages/about/About";
import Starfood from "./pages/starfood/Starfood";
import Notions from "./pages/notions/Notions";
import Confectionery from "./pages/confectionery/Confectionery";
import ConfectioneryBrand from "./pages/confectioneryBrand/ConfectioneryBrand";
import Factories from "./pages/factories/Factories";
import Laronda from "./pages/laronda/Laronda";
import PrivateLabels from "./pages/private_labels/PrivateLabels";
import OurExport from "./pages/ourExport/OurExport";
import Snacks from "./pages/snacks/Snacks";
import Baking from "./pages/backing/Baking";
import Malban from "./pages/malban/Malban";
import DutyFree from "./pages/dutyFree/DutyFree";
import Retails from "./pages/retails/Retails";
import SnackBrand from "./pages/snackBrand/SnackBrand";
import SubBrands from "./pages/snackSubBrand/SubBrands";
import Contact from "./pages/contact/Contact";
import Career from "./pages/career/Career";
import Subconfbrand from "./pages/confectionerySubBrand/Subconfbrand";
import Arabian from "./pages/confectionerySubBrand/Arabian";
import Error from "./pages/Error/Error";
import Catalog from "./pages/catalog/Catalog";
import CatalogPageOne from "./pages/catalog/CatalogPageOne";
import CatalogPageTwo from "./pages/catalog/CatalogPageTwo";
import CatalogPageThree from "./pages/catalog/CatalogPageThree";
import CatalogPageFour from "./pages/catalog/CatalogPageFour";
import Brands from "./pages/brands/Brands";
import Login from "./pages/login/Login";
import Register from "./pages/login/Register";
import InquiryData from "./pages/inquiryData/InquiryData";
import { ProtectedRoute } from "./util/ProtectedRoute";
import { AuthContext } from "./context/AuthContext";

const App = () => {
  const { user } = useContext(AuthContext);
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/factories" element={<Factories />} />
        <Route path="/starfood" element={<Starfood />} />
        <Route path="/notions" element={<Notions />} />
        <Route path="/confectionery" element={<Confectionery />} />
        <Route path="/confectionery_brands" element={<ConfectioneryBrand />} />
        <Route path="/laronda" element={<Laronda />} />
        <Route path="/private_labels" element={<PrivateLabels />} />
        <Route path="/export" element={<OurExport />} />
        <Route path="/snacks" element={<Snacks />} />
        <Route path="/baking" element={<Baking />} />
        <Route path="/malban" element={<Malban />} />
        <Route path="/dutyfree" element={<DutyFree />} />
        <Route path="/retails" element={<Retails />} />
        <Route path="/sub_snack_brands" element={<SubBrands />} />
        <Route path="/snacks_brand" element={<SnackBrand />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/chocodate" element={<Subconfbrand />} />
        <Route path="/arabian_delights" element={<Arabian />} />
        <Route path="*" element={<Error />} />
        <Route path="/index.php/catalog" element={<Catalog />} />
        <Route path="/chocolate-catalogue" element={<CatalogPageOne />} />
        <Route path="/brands" element={<Brands />} />
        <Route
          path="/inquiry"
          element={
            <ProtectedRoute>
              <InquiryData />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />

        <Route path="/register" element={<Register />} />
        {/* <Route path="/inquiry" element={<InquiryData />} /> */}
        <Route
          path="/luxury-chocolate-catalogue"
          element={<CatalogPageTwo />}
        />
        <Route path="/snacks-catalogue" element={<CatalogPageThree />} />
        <Route path="/private-label-catalogue" element={<CatalogPageFour />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
};

export default App;
